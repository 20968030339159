.logo {
    max-width: 275px;
    margin: 20px 0;
}
.nav-logo-img{
    width: 300px;
}
.navbar {
    align-items: center;
    background: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    font-family: sans-serif;
    padding: 10px 50px;
}
.push-left {
    /* margin-left: auto; */

}
/* Menu */
.menu-toggler{
    z-index: 100000;
}
.hamburger {
    background: transparent;
    border: none;
    cursor: pointer;
    display: none;
    outline: none;
    height: 30px;
    position: relative;
    width: 30px;
    z-index: 1000;
}

.hamburger-line {
    background: #272727;
    height: 3px;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-out;
    width: 100%;
}
.hamburger:hover .hamburger-line {
    background: #777;
}
.hamburger-line-top {
    top: 3px;
}
.menu-active .hamburger-line-top {
    top: 50%;
    transform: rotate(45deg) translatey(-50%);
}
.hamburger-line-middle {
    top: 50%;
    transform: translatey(-50%);
}
.menu-active .hamburger-line-middle {
    left: 50%;
    opacity: 0;
    width: 0;
}
.hamburger-line-bottom {
    bottom: 3px;
}
.menu-active .hamburger-line-bottom {
    bottom: 50%;
    transform: rotate(-45deg) translatey(50%);
}
.nav-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: all 0.25s ease-in;
}
@media screen and (max-width: 768px) {
    .nav-menu {
        background: #fff;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translatey(-100%);
        text-align: center;
        height: 100vh;
        z-index: 100!important;
   }
    .menu-active .nav-menu {
        transform: translatey(0%);
        opacity: 1;
   }
}
.nav-menu .menu-item a {
    color: #444;
    display: block;
    line-height: 30px;
    margin: 0px 10px;
    text-decoration: none;
    text-transform: uppercase;
}
.nav-menu .menu-item a:hover {
    color: #777;
    text-decoration: underline;
}
.mobile-nav-btn{
    width: 100%;
    display: none;
    padding: 15px;
}
.mobile-nav-btn-link{
    width: 100%;
}
.mobile-nav-btn button{
    width: 100%;
    z-index:100000;
}
@media screen and (max-width: 768px) {
    .nav-menu .menu-item a {
        font-size: 20px;
        margin: 8px;
   }
   .mobile-nav-btn{
       display: block;
   }
}
.sub-nav {
    border: 1px solid #ccc;
    display: none;
    position: absolute;
    background-color: #fff;
    padding: 5px 5px;
    list-style: none;
    width: 230px;
}
@media screen and (max-width: 768px) {
    .sub-nav {
        position: relative;
        width: 100%;
        display: none;
        background-color: rgba(0, 0, 0, 0.20);
        box-sizing: border-box;
   }
}
.nav__link:hover + .sub-nav {
    display: block;
}
.sub-nav:hover {
    display: block;
}
@media screen and (max-width: 768px) {
    .hamburger {
        display: inline-block;
   }
}
@media screen and (max-width: 994px){
    .nav-logo-img{
        width: 200px;
    }
}