.video-embed {
  width: 100%;
  height: 250px;
}
.video-embed-mid {
  width: 450px;
  height: 270px;
  margin: 45px auto;
  display: block;
}
.video-title {
  font-size: 16pt;
}
.video-col {
  margin-bottom: 35px;
}
@media screen and (max-width: 466px) {
  .video-embed-mid {
    width: 100%;
  }
}
